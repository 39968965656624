import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { Link } from "gatsby"

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <div className="text-center mt-8">
      <h1 className="text-2xl mb-4">Not Found</h1>
      <p>Page not found.  <Link to="/">Visit the homepage</Link></p>
    </div>
  </Layout>
)

export default NotFoundPage
